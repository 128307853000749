import firebase from 'firebase';

const config = {
    apiKey: "AIzaSyB1wsYSJXWesdE_sL_R22YxwdfiaBaRWaA",
    authDomain: "fir-test-fa9af.firebaseapp.com",
    databaseURL: "https://fir-test-fa9af.firebaseio.com",
    projectId: "fir-test-fa9af",
    storageBucket: "fir-test-fa9af.appspot.com",
    messagingSenderId: "101506974237"
  };
  firebase.initializeApp(config);
  
  export default firebase;